import style from "./section.module.css";
import bags from "../../assets/bags.svg";

export default function Section() {
  return (
    <div className={style.introduction}>
      <div className={style.IntroductionTextSide}>
        <h2>Beneficios</h2>
        <h4>Contribuye al medio ambiente</h4>
        <p>
          Al utilizar nuestra aplicación de entrega de bebidas, estarás
          contribuyendo activamente al cuidado del medio ambiente. Nuestro
          enfoque en la sostenibilidad significa que cada pedido realizado a
          través de nuestra aplicación ayuda a reducir la generación de residuos
          utilizando Bolsas Reciclables
        </p>
        <h4>Promueve el consumo responsable</h4>
        <p>
          A través de nuestra aplicación, también fomentamos el consumo
          responsable de bebidas. Brindamos información detallada sobre los
          ingredientes, el origen y las opciones de reducción de azúcar,
          permitiéndote tomar decisiones informadas y conscientes sobre tus
          hábitos de consumo.
        </p>
        <h4>Entrega rápida y confiable</h4>
        <p>
          Nuestra aplicación de entrega de bebidas te brinda la comodidad de
          recibir tus bebidas favoritas directamente en tu puerta. Nuestro
          equipo se compromete a realizar entregas rápidas y confiables.
        </p>
      </div>
      <div className={style.IntroductionImgSide}>
        <img id="benefits" className={style.imageIntroduction} src={bags}></img>
      </div>
    </div>
  );
}
