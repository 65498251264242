import React from "react";
import style from "./intro.module.css";
import app from "../../assets/app.svg";

export default function Introduction() {
  return (
    <div id="whatIs" className={style.introduction}>
      <img className={style.leaf} src={require("../../assets/hoja.png")} />
      <img className={style.leaf} src={require("../../assets/hoja.png")} />
      <img className={style.leaf} src={require("../../assets/hoja.png")} />
      <div className={style.IntroductionImgSide}>
        <svg
          className={style.svgIntro}
          viewBox="0 0 1200 600"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1">
          <g transform="translate(15.14310223262919 571.3062696383294)">
            <path
              d="M190.2 -230.9C281.2 -195.7 413.5 -186.5 495.7 -117.6C577.9 -48.7 609.9 79.9 575.4 187.2C540.8 294.4 439.7 380.2 332.7 399.4C225.7 418.7 112.9 371.3 2.1 368.5C-108.7 365.7 -217.5 407.3 -323 387.6C-428.6 367.9 -531 286.9 -582.5 177.6C-634 68.4 -634.7 -69 -564.9 -152.5C-495.1 -236 -354.8 -265.7 -248.9 -296.1C-143 -326.5 -71.5 -357.8 -11 -342.7C49.6 -327.6 99.2 -266.2 190.2 -230.9"
              fill="#015441"></path>
          </g>
        </svg>
        <img className={style.imageIntroduction} src={app}></img>
      </div>
      <div className={style.IntroductionTextSide}>
        <p>
          Descubre nuestra aplicación de entrega de bebidas enfocada en el
          cuidado del medio ambiente. Ofrecemos una amplia selección de bebidas
          refrescantes y sostenibles. Nuestra misión es brindarte una
          experiencia conveniente y ecológica, utilizando envases biodegradables
          y ingredientes orgánicos de comercio justo. Al elegir nuestra
          aplicación, estarás reduciendo la generación de residuos y minimizando
          la huella de carbono. Únete a nosotros y descarga nuestra aplicación
          para disfrutar de tus bebidas favoritas de forma sostenible.
        </p>
      </div>
    </div>
  );
}
