import "./App.css";
import Benefits from "./components/intro/introduction";
import Header from "./components/header/header";
import Section from "./components/section2/section";
import Cards from "./components/cards/cards";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Benefits></Benefits>
      <Section></Section>
      <Cards></Cards>
      <Footer></Footer>
    </div>
  );
}

export default App;
