import style from "./footer.module.css";
import stores from "../../assets/footer/tienda1.svg";

export default function Footer() {
  return (
    <div id="contactus" className={style.footerFinal}>
      <h2>Contactanos</h2>
      <div className={style.informationSideFooter}>
        <div>
          <p>79 Av. Sur, pasaje A #222 Colonia San Benito, El Salvador</p>
          <p>+503 7106 9877</p>
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <a
            href="https://www.instagram.com/barlleno/?hl=es-la"
            rel="noopener noreferrer">
            <img
              style={{ width: 40 }}
              src={require("../../assets/footer/instagram.png")}
              alt="instagram"></img>
          </a>
          <a href="https://www.facebook.com/barlleno" rel="noopener noreferrer">
            <img
              style={{ width: 40 }}
              src={require("../../assets/footer/facebook.png")}
              alt="facebook"></img>
          </a>
          <a href="https://www.tiktok.com/@barlleno" rel="noopener noreferrer">
            <img
              style={{ width: 40 }}
              src={require("../../assets/footer/tiktok.png")}
              alt="Tit Tok"></img>
          </a>
        </div>
      </div>
      <div className={style.rightReserved}>
        Made with ❤ by{" "}
        <a href="https://premte.com/" rel="noopener noreferrer">
          Premier Tec
        </a>{" "}
        | Copyright © {new Date().getFullYear()} | All rights reserved
      </div>
    </div>
  );
}
