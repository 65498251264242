import React from "react";
import style from "./header.module.css";
import Navbar from "../navbar/navbar";

export default function Header() {
  return (
    <div className={style.header}>
      <div className={style.headerContainer}>
        <Navbar></Navbar>
        <div className={style.headerContent}>
          <div className={style.contentInformationHeader}>
            <h1>
              Refresca el planeta: Descubre nuestra aplicación de entrega de
              bebidas eco-friendly
            </h1>
            <p>
              La nueva forma refrescante de cuidar del planeta mientras
              disfrutas de tus bebidas favoritas
            </p>

            <a
              style={{ textDecoration: "none" }}
              href="https://barlleno.app/"
              rel="noopener noreferrer">
              <div className={style.buttonHeader}>Conocer Ahora</div>
            </a>
          </div>
          <div className="imageSideBanner">
            <div className={style.imageFrontBanner}>
              <img
                style={{ height: 300 }}
                src={require("../../assets/banner/barllenoEco.png")}
                alt="banner"></img>
            </div>
          </div>
        </div>
      </div>
      <div className={style.pattern} style={{ width: "100%" }}>
        <svg
          className={style.svgHide}
          viewBox="0 0 900 600"
          width="100%"
          height="100%"
          version="1.1">
          <g transform="translate(789.8184037365816 92.44242184202082)">
            <path
              d="M454.1 -668.4C556.7 -640.6 586.3 -460.9 580.9 -312.3C575.5 -163.8 535.2 -46.3 498.6 57.8C461.9 161.8 429 252.5 362.7 296C296.5 339.4 197 335.7 103.2 373C9.5 410.3 -78.5 488.7 -157.2 487.5C-236 486.3 -305.4 405.6 -317.9 316.8C-330.3 228.1 -285.8 131.4 -282.8 50.1C-279.7 -31.2 -318.1 -97 -342.3 -202.9C-366.4 -308.8 -376.2 -454.7 -316.8 -502.2C-257.3 -549.8 -128.7 -498.9 23.5 -535.5C175.7 -572.1 351.4 -696.1 454.1 -668.4"
              fill="#015441"></path>
          </g>
        </svg>
      </div>
    </div>
  );
}
