import style from "./cards.module.css";

export default function Cards() {
  return (
    <div id="features" className={style.cards}>
      <div className={style.card}>
        <img src={require("../../assets/cards/drinks.png")}></img>
        <h2>Programa de recompensas</h2>
        <p>
          Valoramos tu compromiso. Por eso, hemos creado un programa de
          recompensas que te permite acumular puntos por cada pedido que
          realices. Canjea tus puntos por descuentos u otras promociones
          exclusivas.
        </p>
      </div>
      <div className={style.card}>
        <img src={require("../../assets/cards/ecology.png")}></img>
        <h2>Compromiso con el Medio Ambiente</h2>
        <p>
          Nos enorgullece nuestro fuerte compromiso con el cuidado del medio
          ambiente. En todas nuestras operaciones, nos esforzamos por tomar
          decisiones eco-friendly y reducir nuestro impacto en el planeta.
        </p>
      </div>
      <div className={style.card}>
        <div className={style.content}>
          <img src={require("../../assets/cards/drinks.png")}></img>
          <h2>Amplia selección</h2>
          <p>
            Creemos en la transparencia y en proporcionarte la información
            necesaria para tomar decisiones conscientes. En nuestra aplicación,
            encontrarás detalles sobre los ingredientes, los proveedores y las
            prácticas de producción
          </p>
        </div>
      </div>
      <div className={style.card}>
        <img src={require("../../assets/cards/location.png")}></img>
        <h2>Seguimiento en tiempo real</h2>
        <p>
          Con nuestra aplicación, podrás realizar un seguimiento en tiempo real
          de tu pedido. Te proporcionaremos actualizaciones en cada etapa, desde
          la preparación hasta la entrega, para que estés informado y puedas
          anticiparte a la llegada de tus bebidas.
        </p>
      </div>
      <div className={style.card}>
        <img src={require("../../assets/cards/time.png")}></img>
        <h2>Pedidos programados</h2>
        <p>
          Además de las entregas rápidas, ofrecemos la opción de programar tus
          pedidos con anticipación. Si sabes que necesitarás tus bebidas en un
          horario específico, puedes programar la entrega para que llegue en el
          momento más conveniente para ti.
        </p>
      </div>
    </div>
  );
}
