import React, { useEffect, useState } from "react";
import style from "./navbar.module.css";
import logo from "../../assets/logoBlanco.svg";

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  // Usa useEffect para agregar un event listener al evento de scroll y actualizar el estado
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyles = {
    backgroundColor: scrolled ? "white" : "",
    color: scrolled ? "#015441" : "",
    // Otros estilos adicionales para el Navbar
  };
  return (
    <div
      style={
        scrolled
          ? {
              backgroundColor: "white",
              color: "#015441",
              width: "100%",
              position: "fixed",
              height: 90,
            }
          : {}
      }>
      <div style={navbarStyles} className={style.header}>
        <a
          href="/"
          onClick={() => window.location.reload()}
          className={style.logo}>
          <img className={style.logoImage} src={logo} alt="logo"></img>
        </a>
        <input className={style.menuBtn} type="checkbox" id="menuBtn" />
        <label className={style.menuIcon} htmlFor="menuBtn">
          <span className={style.navicon}></span>
        </label>
        <ul className={style.menu}>
          <li>
            <a style={navbarStyles} href="#whatIs">
              ¿Qué es?
            </a>
          </li>
          <li>
            <a style={navbarStyles} href="#benefits">
              Beneficios
            </a>
          </li>
          <li>
            <a style={navbarStyles} href="#features">
              Características
            </a>
          </li>
          <li>
            <a style={navbarStyles} href="#contactus">
              Contactanos
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
